<template>
  <div class="transactions">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <meter-reading-details-card
      :meter-reading="details"
      :is-loading="isLoading"
    />
    <br>
    <initial-debt-payments
      v-if="details && details.user.unaccounted_debts && details.user.unaccounted_debts.length > 0"
      :is-loading="isLoading"
      :payments="details.user.unaccounted_debts"
    /><br>
    <meter-billing-details-card
      :billings="details.meter_billings"
      :is-loading="isLoading"
    />
    <br>
    <meter-details-card
      :meter="details.meter"
      :is-loading="isLoading"
    />
    <br>
    <user-details-card
      :user="details.user"
      :is-loading="isLoading"
    />
  </div>
</template>
<script>
import axios from 'axios'
import UserDetailsCard from '@/components/details-card/UserDetailsCard.vue'
import MeterDetailsCard from '@/components/details-card/MeterDetailsCard.vue'
import MeterReadingDetailsCard from '@/components/details-card/MeterReadingDetailsCard.vue'
import MeterBillingDetailsCard from '@/components/details-card/MeterBillingDetailsCard.vue'
import InitialDebtPayments from '@/components/details-card/InitialDebtPayments.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    UserDetailsCard,
    MeterDetailsCard,
    MeterReadingDetailsCard,
    MeterBillingDetailsCard,
    InitialDebtPayments,
  },
  data() {
    return {
      search: '',
      details: { user: {}, meter: {}, meter_billings: [] },
      isLoading: false,
      meterReadingId: this.$route.params.id,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Meter Readings',
          disabled: false,
          to: { name: 'meter-readings' },
        },
      ],
    }
  },
  mounted() {
    this.getDetails()
    if (this.meterReadingId) {
      this.breadcrumbs.push(
        {
          text: this.meterReadingId,
          disabled: true,
          to: { name: 'meter-readings-details' },
        },
      )
    }
  },
  methods: {
    getDetails() {
      this.isLoading = true
      axios
        .get(`meter-readings/${this.meterReadingId}`)
        .then(response => {
          this.details = response.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
