<template>
  <v-card>
    <v-card-title class="mb-0 pb-1">
      Meter Billing Information
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-simple-table
        min-height="160px"
        class="mt-4"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th
                id="amount"
                class="text-left"
              >
                Amount Paid
              </th>
              <th
                id="balance"
                class="text-left"
              >
                Balance
              </th>
              <th
                id="amount"
                class="text-left"
              >
                Credit Applied
              </th>
              <th
                id="amount"
                class="text-left"
              >
                Amount Credit
              </th>
              <th
                id="balance"
                class="text-left"
              >
                Date Paid
              </th>
            </tr>
          </thead>
          <thead v-show="isLoading">
            <tr>
              <td colspan="3">
                <v-progress-linear
                  indeterminate
                  class="mt-2"
                  rounded
                ></v-progress-linear>
                <p class="text-center mt-1">
                  Loading...Please wait
                </p>
              </td>
            </tr>
          </thead>
          <tbody v-if="billings.length > 0">
            <tr
              v-for="bill in billings"
              :key="bill.id"
            >
              <td>{{ bill.amount_paid }}</td>
              <td>{{ bill.balance }}</td>
              <td>{{ bill.credit }}</td>
              <td>{{ bill.amount_over_paid }}</td>
              <td>{{ bill.created_at | formatDate }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isLoading">
              <td
                colspan="5"
                style="text-align: center"
              >
                No billings found
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    billings: {
      type: Array,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style>

</style>
